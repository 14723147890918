import React, { Component, createRef } from 'react';
import { Link, Navigate } from "react-router-dom";

import DataTable from 'components/blocks/data_table/DataTable';
import WdForm from 'components/wd/WdForm';

import IconButton from '@mui/material/IconButton';

import Btn from 'components/Btn';

import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import MenuItem from '@mui/material/MenuItem';

import Popover from '@mui/material/Popover';

import Main from 'components/Main';

import Api from 'api/Api';

import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';

import Person from '@mui/icons-material/Person';
import AppRegistration from '@mui/icons-material/AppRegistration';
import Badge from '@mui/icons-material/Badge';
import CardMembership from '@mui/icons-material/CardMembership';
import School from '@mui/icons-material/School';
import EmojiEvents from '@mui/icons-material/EmojiEvents';
import Star from '@mui/icons-material/Star';
import Clear from '@mui/icons-material/Clear';
import CloudDownload from '@mui/icons-material/CloudDownload';

import LayoutHelper from 'helpers/LayoutHelper';

import ProfessionalsCard from 'sections/ProfessionalsCard';

import ProfessionalsCertifications from './ProfessionalsCertifications';
import ProfessionalsWorkExperience from './ProfessionalsWorkExperience';
import ProfessionalsEducation from './ProfessionalsEducation';
import ProfessionalsAwards from './ProfessionalsAwards';
import ProfessionalsTestimonials from './ProfessionalsTestimonials';

import Chart from 'react-apexcharts'

class ProfessionalsList extends Component { 
    constructor(props) {
        super();
        this.state = {

            account_token: '',
            error_message: '',
            success_message: '',

            loading: false,

            user: false,

            row: false,
            active_row: false,

            reloading: false,
            do_reload: false,

            anchorEl: null,

            redirect: '',

            form_popup: false,
            
            states: [],

            specialisation_options_personal_trainer: [],
            specialisation_options_dietician: [],
            specialisation_options_management: [],
            specialisation_options_yoga: [],
            specialisation_options_physiotherapy: [],

            filter_specialisation_options_personal_trainer: [],
            filter_specialisation_options_dietician: [],
            filter_specialisation_options_management: [],
            filter_specialisation_options_yoga: [],
            filter_specialisation_options_physiotherapy: [],

            applied_specialisation_filters: [],

            specialisation_filters: false,

            filter_params: [],

            select_all: false,
            filters: {},

            selected_data: [],

            selected_bulk_action: false,
            close_bulk_action: false,

            selected_data: [],
        }

        this.filter = createRef();
    }

    componentDidMount = () => {

        let account_token = localStorage.getItem(process.env.REACT_APP_ACCOUNT_TOKEN);
        let user = localStorage.getItem(process.env.REACT_APP_ACCOUNT_USER);
        
        if(account_token && user){

            this.setState({account_token: account_token}, () => {

                this.init(account_token)
            });
        }else{

            // window.location = Api.url + 'logout';
        }
    }

    render () {

        let options = {
            chart: {
                type: "radialBar",
            },
            colors: ["rgba(62, 147, 252, 1)"],
            plotOptions: {
                radialBar: {
                    hollow: {
                        margin: 0,
                        size: "10%",
                        background: "rgba(0,0,0,0)"
                    },
                    track: {
                        background: 'rgba(0,0,0,.1)',
                        dropShadow: {
                            enabled: false,
                            top: 5,
                            left: 0,
                            blur: 4,
                            opacity: 0.15
                        }
                    },
                    dataLabels: {
                        name: {
                            offsetY: -10,
                            color: "#fff",
                            fontSize: "0px",
                            show: false
                        },
                        value: {
                            offsetY: 5,
                            color: "#050C9C",
                            fontSize: "0px",
                            fontWeight: 'bold',
                            show: true
                        }
                    }
                }
            },
            fill: {
                type: "gradient",
                gradient: {
                    shade: "dark",
                    type: "vertical",
                    gradientToColors: ["rgba(239, 183, 192, 1)"],
                    stops: [0, 100]
                }
            },
            stroke: {
                lineCap: "round"
            },
            labels: ["Progress"]
        };

        if(this.state.redirect !== ''){

            return <Navigate to={`/${this.state.redirect}`} />
        }

        return (

            <Main
                active_page="professionals"

                error_message={this.state.error_message}
                success_message={this.state.success_message}

                page="professionals"
                title={"Professionals"}
                title_action={[{key: 'professionals_add', label: 'Add Member', onClick: () => {

                    this.setState({form_popup: true})
                }}]}
            >

                {(this.state.account_token) &&

                    <>

                        <DataTable
                            index="professionals"
                            label="Professional"

                            loading={this.state.reloading}

                            active_row={this.state.active_row}

                            onSelectAll={(select, filters) => {

                                this.setState({select_all: select, filters: filters})
                            }}
                            onRowCheck={(selected_data) => {

                                this.setState({selected_data});
                            }}

                            bulk_actions={[
                                <MenuItem key="bulk_assign_user" value="assign_user" onClick={(e) => {
    
                                    if(this.state.selected_data.length > 0){
                                    
                                        this.setState({selected_bulk_action: 'assign_user'});
                                    }
    
                                }}>Send SMS</MenuItem>
                            ]}

                            selected_bulk_action={this.state.selected_bulk_action}
                            close_bulk_action={this.state.close_bulk_action}
                            onBulkActionClose={() => {

                                this.setState({selected_bulk_action: false, close_bulk_action: false, bulk_mobile_numbers: []});
                            }}

                            // list_component={(row) => {

                            //     return (
                            //         <ProfessionalsCard
                            //             row={row}
                            //             editable={true}
                            //             onEdit={(row) => {

                            //                 this.setState({row: row}, () => {

                            //                     this.setState({form_popup: true})
                            //                 })
                            //             }}
                            //         />
                            //     )
                            // }}
                            // list_component_spacing={5}
                            // list_component_size={3}
                            // list_item_box_styles={{padding: 0}}

                            toolbar_actions={[
                                {key: 'add_module', label: 'Export CSV', 'startIcon': <CloudDownload />, onClick: () => {
                        
                                    let a = document.createElement('a');
                                    a.href = `${Api.url}data/export/download/${this.state.account_token}`;
                                    a.setAttribute('target', '_blank');
                                    a.click();
                                }}
                            ]}

                            do_reload={this.state.do_reload}
                            relaodDone={() => {

                                this.setState({do_reload: false});
                            }}

                            filter_params={this.state.filter_params}

                            beforeUpperToolbar={
                                <div ref={this.filter}>
                                    <div className='justify-start'>
                                        <strong className='fs-14'>Specialisation Filters:</strong>
                                        <div className='ml-10'>
                                            {this.state.applied_specialisation_filters.length > 0
                                                ?
                                                    <div>

                                                        {this.state.applied_specialisation_filters.map((_applied_specialisation_filters, index) => {

                                                            return <Btn size="small" onClick={(e) => {

                                                                this.setState({specialisation_filters: true})
                                                            }} key={`spfilter_${index}`}>
                                                                <span className='gr-9'>{_applied_specialisation_filters.value}</span>
                                                            </Btn>
                                                        })}

                                                        <Btn size="small" onClick={(e) => {

                                                            this.setState({specialisation_filters: true})
                                                        }}>Chanage Specialisation Filters</Btn>

                                                        <Btn size="small" startIcon={<Clear />} onClick={(e) => {

                                                            this.setState({
                                                                filter_specialisation_options_personal_trainer: [],
                                                                filter_specialisation_options_dietician: [],
                                                                filter_specialisation_options_management: [],
                                                                filter_specialisation_options_yoga: [],
                                                                filter_specialisation_options_physiotherapy: [],
                                                    
                                                                applied_specialisation_filters: [],
                                                    
                                                                specialisation_filters: false,
                                                    
                                                                filter_params: []}, () => {

                                                                    this.setState({do_reload: true})
                                                                })
                                                        }}>Clear Applied Filters</Btn>
                                                    </div>
                                                :
                                                    <Btn size="small" onClick={(e) => {

                                                        this.setState({specialisation_filters: true})
                                                    }}>Apply Specialisation Filters</Btn>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }

                            columns={[
                                {name: 'action', column: 'row_id', selectable: true},
                                {name: '', column: 'id', sortable: false, hide_search: true, width: 60, search_type: 'lt', search_placeholder: 'Search less then', renderer: (row) => {

                                    return (
                                        <div style={{width: 70, height: 70}}>
                                            <div style={{backgroundImage:`url(${row.profile_pic_url})`, width: '100%', height: '100%', borderRadius: 10, backgroundSize: 'cover', backgroundPosition: 'center center'}}></div>
                                        </div>
                                    )
                                }},
                                {name: 'Profile Views', column: 'views', sortable: true},
                                {name: 'Profile %', column: 'progress', sortable: true, width: 60, search_type: 'lt', search_placeholder: 'Search less then', renderer: (row) => {

                                    return (
                                        <div className='justify-start vertical'>
                                            <div style={{marginLeft: 0, marginTop: -10}}>
                                                <Chart
                                                    type="radialBar"
                                                    series={[row.progress]}
                                                    options={options}
                                                    width={140}
                                                />
                                            </div>
                                            <div className='justify-center' style={{marginTop: -20}}>
                                                <div className=''>
                                                    <strong className='fs-12 gr-5'>{row.progress}%</strong>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }},
                                {name: 'First Name', column: 'first_name', sortable: true},
                                {name: 'Last Name', column: 'last_name', sortable: true},
                                {name: 'Username', column: 'slug', sortable: true},
                                {name: 'Email', column: 'email', sortable: true},
                                {name: 'Mobile', column: 'mobile', sortable: true, renderer: (row) => {

                                    return (
                                        <span>{row.country_code} {row.mobile}</span>
                                    )
                                }},
                                {name: 'State', column: 'state_code', sortable: true, search_type: 'match', search_input: 'dropdown', search_data: this.state.states},
                                {name: 'City', column: 'city', sortable: true},
                                {name: 'Member Since', column: 'added_on', sortable: true, renderer: (row) => {

                                    return (
                                        <div className='vertical'>
                                            <span>{row.added_on_formatted}</span>
                                            <span className='gr-6'>{row.member_since}</span>
                                        </div>
                                    )
                                }},
                            ]}

                            row_actions={(row, row_index) => {

                                return (

                                    <div className="hoverable-action">
                                        <div className="align-start">

                                            <IconButton size="small" color="primary" onClick={(e) => {

                                                this.setState({row: row, active_row: row_index}, () => {

                                                    this.setState({form_popup: true})
                                                })
                                            }}>
                                                <Edit style={{fontSize: 18}} />
                                            </IconButton>

                                            <Btn title="Public Profile" icon={true} size="small" color="primary" confirm_message="Username is not defined. Please enter username for this profile to view the public profile." confirm={row.slug !== '' ? false : true} onClick={(e) => {

                                                if(row.slug !== ''){

                                                    let a = document.createElement('a');
                                                    a.href = `https://linkfit.in/professional/${row.slug}`;
                                                    a.setAttribute('target', '_blank');
                                                    a.click();
                                                }
                                            }}>
                                                <Badge style={{fontSize: 18}} />
                                            </Btn>

                                            <Btn icon={true} size="small" color="primary" confirm_message="Do you really want to delete this account?" confirm={true} onClick={(e) => {

                                                this.removeAccount(row)
                                            }}>
                                                <Delete style={{fontSize: 18}} className='c-red' />
                                            </Btn>
                                        </div>
                                    </div>
                                )
                            }}

                            per_page={12}

                            default_sort_by="added_on"
                            sort_by_dir="asc"

                            api_url="backend/professionals/listing"

                            account_token={this.state.account_token}
                            
                            row_id="row_id"
                            view_url="employees/leave_application/listing"
                        />

                        <WdForm
                            
                            title='Professional'
                            back_label="Cancel"

                            drawer={true}
                            open={this.state.form_popup}

                            size="large"

                            submit_url='backend/professionals/save'
                            data_url='backend/professionals/data'

                            submit_continue={true}

                            onSubmit={(result) => {

                                this.setState({form_popup: false, do_reload: true, active_row: false, row_id: '', row: false})
                                LayoutHelper.addSuccessMessage(this, result.message)
                            }}
                            onBack={() => {

                                this.setState({form_popup: false, active_row: false, row_id: '', row: false})
                            }}
                        
                            row_id={this.state.row.row_id}
                            id="row_id"
                            title_field="first_name"
                            updated_on="updated_on_formatted"
                                                    
                            fields={{
                                tabs: this.other_fields()
                            }}
                        />
                    </>
                }

                <Popover
                    open={this.state.specialisation_filters}
                    anchorEl={this.filter.currentTarget}
                    onClose={() => {

                        this.setState({specialisation_filters: false})
                    }}
                    anchorOrigin={{
                        vertical: 'left',
                        horizontal: 'bottom',
                    }}
                >
                    <div className='popover-confirm xl'>
                        <div className='popover-header'>
                            <div className='popover-title'>
                                <strong>Filter Specialisations</strong>
                            </div>
                        </div>
                        <div className='popover-body'>
                            <div>
                                <strong className='fs-14 c-blue'>Personal Trainer ({this.state.filter_specialisation_options_personal_trainer.length})</strong>
                                <FormGroup style={{display: 'flex', flexDirection: 'row'}}>
                                    {this.state.specialisation_options_personal_trainer.map((_specialisation_options_personal_trainer, index) => {

                                        return (
                                            <FormControlLabel key={`pt_${index}`}
                                                control={
                                                    <Checkbox
                                                        size='sm'
                                                        checked={this.state.filter_specialisation_options_personal_trainer.indexOf(_specialisation_options_personal_trainer.key) > -1 ? true : false}
                                                        value={_specialisation_options_personal_trainer.key}
                                                        onChange={(e) => {

                                                            let applied_specialisation_filters = this.state.applied_specialisation_filters;
                                                            let filter_specialisation_options_personal_trainer = this.state.filter_specialisation_options_personal_trainer;

                                                            if(e.target.checked){

                                                                filter_specialisation_options_personal_trainer.push(_specialisation_options_personal_trainer.key)

                                                                applied_specialisation_filters.push(_specialisation_options_personal_trainer)
                                                            }else{

                                                                filter_specialisation_options_personal_trainer.splice(filter_specialisation_options_personal_trainer.indexOf(_specialisation_options_personal_trainer.key), 1);

                                                                const _i = applied_specialisation_filters.findIndex(row => row.key === _specialisation_options_personal_trainer.key);

                                                                if(_i > -1){

                                                                    applied_specialisation_filters.splice(_i, 1);
                                                                }
                                                            }

                                                            this.setState({filter_specialisation_options_personal_trainer: filter_specialisation_options_personal_trainer, applied_specialisation_filters: applied_specialisation_filters})
                                                        }}
                                                    />
                                                }
                                                label={_specialisation_options_personal_trainer.value}
                                            />
                                        )
                                    })}
                                </FormGroup>
                            </div>

                            <div className='mt-10'>
                                <strong className='fs-14 c-blue'>Nutritionist ({this.state.filter_specialisation_options_dietician.length})</strong>
                                <FormGroup style={{display: 'flex', flexDirection: 'row'}}>
                                    {this.state.specialisation_options_dietician.map((_specialisation_options_dietician, index) => {

                                        return (
                                            <FormControlLabel key={`ntr_${index}`}
                                                control={
                                                    <Checkbox
                                                        size='sm'
                                                        checked={this.state.filter_specialisation_options_dietician.indexOf(_specialisation_options_dietician.key) > -1 ? true : false}
                                                        value={_specialisation_options_dietician.key}
                                                        onChange={(e) => {

                                                            let applied_specialisation_filters = this.state.applied_specialisation_filters;
                                                            let filter_specialisation_options_dietician = this.state.filter_specialisation_options_dietician;

                                                            if(e.target.checked){

                                                                filter_specialisation_options_dietician.push(_specialisation_options_dietician.key)

                                                                applied_specialisation_filters.push(_specialisation_options_dietician)
                                                            }else{

                                                                filter_specialisation_options_dietician.splice(filter_specialisation_options_dietician.indexOf(_specialisation_options_dietician.key), 1);

                                                                const _i = applied_specialisation_filters.findIndex(row => row.key === _specialisation_options_dietician.key);

                                                                if(_i > -1){

                                                                    applied_specialisation_filters.splice(_i, 1);
                                                                }
                                                            }

                                                            this.setState({filter_specialisation_options_dietician: filter_specialisation_options_dietician, applied_specialisation_filters: applied_specialisation_filters})
                                                        }}
                                                    />
                                                }
                                                label={_specialisation_options_dietician.value}
                                            />
                                        )
                                    })}
                                </FormGroup>
                            </div>

                            <div className='mt-10'>
                                <strong className='fs-14 c-blue'>Management ({this.state.filter_specialisation_options_management.length})</strong>
                                <FormGroup style={{display: 'flex', flexDirection: 'row'}}>
                                    {this.state.specialisation_options_management.map((_specialisation_options_management, index) => {

                                        return (
                                            <FormControlLabel key={`mng_${index}`}
                                                control={
                                                    <Checkbox
                                                        size='sm'
                                                        checked={this.state.filter_specialisation_options_management.indexOf(_specialisation_options_management.key) > -1 ? true : false}
                                                        value={_specialisation_options_management.key}
                                                        onChange={(e) => {

                                                            let applied_specialisation_filters = this.state.applied_specialisation_filters;
                                                            let filter_specialisation_options_management = this.state.filter_specialisation_options_management;

                                                            if(e.target.checked){

                                                                filter_specialisation_options_management.push(_specialisation_options_management.key)

                                                                applied_specialisation_filters.push(_specialisation_options_management)
                                                            }else{

                                                                filter_specialisation_options_management.splice(filter_specialisation_options_management.indexOf(_specialisation_options_management.key), 1);

                                                                const _i = applied_specialisation_filters.findIndex(row => row.key === _specialisation_options_management.key);

                                                                if(_i > -1){

                                                                    applied_specialisation_filters.splice(_i, 1);
                                                                }
                                                            }

                                                            this.setState({filter_specialisation_options_management: filter_specialisation_options_management, applied_specialisation_filters: applied_specialisation_filters})
                                                        }}
                                                    />
                                                }
                                                label={_specialisation_options_management.value}
                                            />
                                        )
                                    })}
                                </FormGroup>
                            </div>

                            <div className='mt-10'>
                                <strong className='fs-14 c-blue'>Yoga ({this.state.filter_specialisation_options_yoga.length})</strong>
                                <FormGroup style={{display: 'flex', flexDirection: 'row'}}>
                                    {this.state.specialisation_options_yoga.map((_specialisation_options_yoga, index) => {

                                        return (
                                            <FormControlLabel key={`yg_${index}`}
                                                control={
                                                    <Checkbox
                                                        size='sm'
                                                        checked={this.state.filter_specialisation_options_yoga.indexOf(_specialisation_options_yoga.key) > -1 ? true : false}
                                                        value={_specialisation_options_yoga.key}
                                                        onChange={(e) => {

                                                            let applied_specialisation_filters = this.state.applied_specialisation_filters;
                                                            let filter_specialisation_options_yoga = this.state.filter_specialisation_options_yoga;

                                                            if(e.target.checked){

                                                                filter_specialisation_options_yoga.push(_specialisation_options_yoga.key)

                                                                applied_specialisation_filters.push(_specialisation_options_yoga)
                                                            }else{

                                                                filter_specialisation_options_yoga.splice(filter_specialisation_options_yoga.indexOf(_specialisation_options_yoga.key), 1);

                                                                const _i = applied_specialisation_filters.findIndex(row => row.key === _specialisation_options_yoga.key);

                                                                if(_i > -1){

                                                                    applied_specialisation_filters.splice(_i, 1);
                                                                }
                                                            }

                                                            this.setState({filter_specialisation_options_yoga: filter_specialisation_options_yoga, applied_specialisation_filters: applied_specialisation_filters})
                                                        }}
                                                    />
                                                }
                                                label={_specialisation_options_yoga.value}
                                            />
                                        )
                                    })}
                                </FormGroup>
                            </div>

                            <div className='mt-10'>
                                <strong className='fs-14 c-blue'>Physiotherapy ({this.state.filter_specialisation_options_physiotherapy.length})</strong>
                                <FormGroup style={{display: 'flex', flexDirection: 'row'}}>
                                    {this.state.specialisation_options_physiotherapy.map((_specialisation_options_physiotherapy, index) => {

                                        return (
                                            <FormControlLabel key={`phy_${index}`}
                                                control={
                                                    <Checkbox
                                                        size='sm'
                                                        checked={this.state.filter_specialisation_options_physiotherapy.indexOf(_specialisation_options_physiotherapy.key) > -1 ? true : false}
                                                        value={_specialisation_options_physiotherapy.key}
                                                        onChange={(e) => {

                                                            let filter_specialisation_options_physiotherapy = this.state.filter_specialisation_options_physiotherapy;
                                                            let applied_specialisation_filters = this.state.applied_specialisation_filters;

                                                            if(e.target.checked){

                                                                filter_specialisation_options_physiotherapy.push(_specialisation_options_physiotherapy.key)
                                                                applied_specialisation_filters.push(_specialisation_options_physiotherapy)

                                                            }else{

                                                                filter_specialisation_options_physiotherapy.splice(filter_specialisation_options_physiotherapy.indexOf(_specialisation_options_physiotherapy.key), 1);

                                                                const _i = applied_specialisation_filters.findIndex(row => row.key === _specialisation_options_physiotherapy.key);

                                                                if(_i > -1){

                                                                    applied_specialisation_filters.splice(_i, 1);
                                                                }
                                                            }

                                                            this.setState({filter_specialisation_options_physiotherapy: filter_specialisation_options_physiotherapy})
                                                        }}
                                                    />
                                                }
                                                label={_specialisation_options_physiotherapy.value}
                                            />
                                        )
                                    })}
                                </FormGroup>
                            </div>
                        </div>
                        <div className='popover-footer'>
                            {/* <Btn onClick={() => {

                                this.setState({specialisation_filters: false})
                            }}>Cancel</Btn> */}
                            <Btn color="secondary" variant="contained"  onClick={() => {

                                let filter_params = [];

                                if(this.state.filter_specialisation_options_personal_trainer.length > 0){

                                    filter_params.push({key: 'specialisations_personal_trainer', value: this.state.filter_specialisation_options_personal_trainer.join(',')})
                                }
                                if(this.state.filter_specialisation_options_dietician.length > 0){

                                    filter_params.push({key: 'specialisations_dietician', value: this.state.filter_specialisation_options_dietician.join(',')})
                                }
                                if(this.state.filter_specialisation_options_management.length > 0){

                                    filter_params.push({key: 'specialisations_management', value: this.state.filter_specialisation_options_management.join(',')})
                                }
                                if(this.state.filter_specialisation_options_yoga.length > 0){

                                    filter_params.push({key: 'specialisations_yoga', value: this.state.filter_specialisation_options_yoga.join(',')})
                                }
                                if(this.state.filter_specialisation_options_physiotherapy.length > 0){

                                    filter_params.push({key: 'specialisations_physiotherapy', value: this.state.filter_specialisation_options_physiotherapy.join(',')})
                                }
                                
                                this.setState({specialisation_filters: false, filter_params: filter_params}, () => {

                                    this.setState({do_reload: true})
                                })
                            }}>Apply</Btn>
                        </div>
                    </div>
                </Popover>
            </Main>
        )
    }

    other_fields = () => {

        let tabs = [];

        tabs.push(
            {
                key: 'general',
                title: 'Personal Details',
                icon: <Person />,
                rows: [
                    {
                        fields: [
                            {key: 'profile_pic', type: 'image', name: 'profile_pic', label: 'Profile Pic', validations: [], span: 6, path: 'professionals/profile_pic/', allowed_types: 'jpg,png,webp', formatted_field: 'profile_pic_url'}
                        ]
                    },
                    {
                        fields: [
                            {key: 'first_name', type: 'input', name: 'first_name', label: 'First Name', validations: ['r'], span: 4},
                            {key: 'last_name', type: 'input', name: 'last_name', label: 'Last Name', validations: ['r'], span: 4},
                        ]
                    },
                    {
                        fields: [
                            {key: 'mobile', type: 'input', name: 'mobile', label: 'Mobile', validations: ['r', 'num', 'min-10', 'unique|backend/professionals/unique/phone'], span: 4},
                            {key: 'email', type: 'input', name: 'email', label: 'Email', validations: ['r', 'email', 'unique|backend/professionals/unique/email'], span: 4},
                        ]
                    },
                    {
                        fields: [
                            {key: 'dob', type: 'date', name: 'dob', label: 'Date Of Birth', validations: ['r'], span: 4},
                        ]
                    },
                    {
                        fields: [
                            {key: 'total_work_exp', type: 'input', name: 'total_work_exp', label: 'Total Work Experience', span: 3},
                            {key: 'no_of_clients', type: 'input', name: 'no_of_clients', label: 'Number Of Clients', span: 3},
                        ]
                    },
                    {
                        fields: [
                            {key: 'address_heading', label: 'Address', type: 'header'}
                        ]
                    },
                    {
                        fields: [
                            {key: 'city', type: 'input', name: 'city', label: 'City', span: 3},
                            {key: 'pincode', type: 'input', name: 'pincode', label: 'Pincode', span: 2},
                            {key: 'state_code', type: 'dropdown', name: 'state_code', label: 'State', span: 3, options: this.state.states},
                        ]
                    },
                    {
                        fields: [
                            {key: 'about_heading', label: 'Other Details', type: 'header'}
                        ]
                    },
                    {
                        fields: [
                            {key: 'about', type: 'editor', name: 'about', label: 'About', span: 12, rows: 5},
                        ]
                    },
                ]
            }
        );

        if(this.state.row !== false){

            tabs.push(
                {
                    key: 'specialisations',
                    title: 'Specialisations',
                    icon: <AppRegistration />,
                    rows: [
                        {
                            fields: [
                                {key: 'specialisations_personal_trainer', type: 'checkbox', name: 'specialisations_personal_trainer', label: 'Personal Trainer', options: this.state.specialisation_options_personal_trainer, span: 12}
                            ]
                        },
                        {
                            fields: [
                                {key: 'specialisations_dietician', type: 'checkbox', name: 'specialisations_dietician', label: 'Nutritionist', options: this.state.specialisation_options_dietician, span: 12}
                            ]
                        },
                        {
                            fields: [
                                {key: 'specialisations_management', type: 'checkbox', name: 'specialisations_management', label: 'Management', options: this.state.specialisation_options_management, span: 12}
                            ]
                        },
                        {
                            fields: [
                                {key: 'specialisations_yoga', type: 'checkbox', name: 'specialisations_yoga', label: 'Yoga', options: this.state.specialisation_options_yoga, span: 12}
                            ]
                        },
                        {
                            fields: [
                                {key: 'specialisations_physiotherapy', type: 'checkbox', name: 'specialisations_physiotherapy', label: 'Physiotherapy', options: this.state.specialisation_options_physiotherapy, span: 12}
                            ]
                        }
                    ]
                }
            )

            tabs.push(
                {
                    key: 'certifications',
                    title: 'Certifications',
                    icon: <Badge />,
                    rows: [
                        {
                            fields: [
                                {
                                    key: 'certifications_html',
                                    type: 'html',
                                    span: 12,
                                    html: <ProfessionalsCertifications
                                                account_token={this.state.account_token}
                                                row_id={this.state.row.row_id}
                                                successMessage={(message) => {

                                                    LayoutHelper.addSuccessMessage(this, message)
                                                }}
                                            />
                                }
                            ]
                        }
                    ]
                }
            )
            
            tabs.push(
                {
                    key: 'work_experience',
                    title: 'Work Experience',
                    icon: <CardMembership />,
                    rows: [
                        {
                            fields: [
                                {
                                    key: 'certifications_html',
                                    type: 'html',
                                    span: 12,
                                    html: <ProfessionalsWorkExperience
                                                account_token={this.state.account_token}
                                                row_id={this.state.row.row_id}
                                                successMessage={(message) => {

                                                    LayoutHelper.addSuccessMessage(this, message)
                                                }}
                                            />
                                }
                            ]
                        }
                    ]
                }
            )
            
            tabs.push(
                {
                    key: 'education',
                    title: 'Education',
                    icon: <School />,
                    rows: [
                        {
                            fields: [
                                {
                                    key: 'certifications_html',
                                    type: 'html',
                                    span: 12,
                                    html: <ProfessionalsEducation
                                                account_token={this.state.account_token}
                                                row_id={this.state.row.row_id}
                                                successMessage={(message) => {

                                                    LayoutHelper.addSuccessMessage(this, message)
                                                }}
                                            />
                                }
                            ]
                        }
                    ]
                }
            )
            
            tabs.push(
                {
                    key: 'awards',
                    title: 'Awards & Achievements',
                    icon: <EmojiEvents />,
                    rows: [
                        {
                            fields: [
                                {
                                    key: 'certifications_html',
                                    type: 'html',
                                    span: 12,
                                    html: <ProfessionalsAwards
                                                account_token={this.state.account_token}
                                                row_id={this.state.row.row_id}
                                                successMessage={(message) => {

                                                    LayoutHelper.addSuccessMessage(this, message)
                                                }}
                                            />
                                }
                            ]
                        }
                    ]
                }
            )
            
            tabs.push(
                {
                    key: 'testimonials',
                    title: 'Testimonials',
                    icon: <Star />,
                    rows: [
                        {
                            fields: [
                                {
                                    key: 'certifications_html',
                                    type: 'html',
                                    span: 12,
                                    html: <ProfessionalsTestimonials
                                                account_token={this.state.account_token}
                                                row_id={this.state.row.row_id}
                                                successMessage={(message) => {

                                                    LayoutHelper.addSuccessMessage(this, message)
                                                }}
                                            />
                                }
                            ]
                        }
                    ]
                }
            )
        }

        return tabs;
    }

    init = (account_token) => {

        var formData = new FormData();
        var that = this;

        formData.append('account_token', account_token);

        Api.post('backend/professionals/init', formData, function(data){

            if(data.status){

                let indian_states = data.states.filter(item => item.country === 'IN');
            
                that.setState({
                    states: indian_states
                });

                const personal_trainer = data.specialisation_options.filter(item => item.head === 'personal_trainer');
                const dietician = data.specialisation_options.filter(item => item.head === 'nutritionist');
                const management = data.specialisation_options.filter(item => item.head === 'management');
                const yoga = data.specialisation_options.filter(item => item.head === 'yoga');
                const physiotherapy = data.specialisation_options.filter(item => item.head === 'physiotherapy');

                that.setState({
                    specialisation_options_personal_trainer: personal_trainer,
                    specialisation_options_dietician: dietician,
                    specialisation_options_management: management,
                    specialisation_options_yoga: yoga,
                    specialisation_options_physiotherapy: physiotherapy,
                });

            }else{

                // LayoutHelper.addErrorMessage(that, data.message);
            }
        });
    }

    removeAccount = (row) => {

        var formData = new FormData();
        var that = this;

        formData.append('account_token', this.state.account_token);
        formData.append('row_id', row.row_id);

        this.setState({reloading: true})

        Api.post('backend/professionals/entity/remove', formData, function(data){

            that.setState({
                reloading: false,
                do_reload: true
            });

            LayoutHelper.addSuccessMessage(that, data.message)
        });
    }
}

export default ProfessionalsList;
